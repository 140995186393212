//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import BenefitsBoomerang from '../../components/marketing/benefitsBoomerang'
import TemplateCalloutLink from '../../components/elements/templateCalloutLink'

const useCaseData = [
  {
    title: 'Equip your affiliates with visual content templates',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      'Drive action for your direct marketing business with visual social content',
    descriptionColor: 'white-3',
    image: 'industryDirectmarketing',
    iconShow: true,
    iconContext: 'money',
    // iconColor: 'sky',
    // lineClass: 'green-1',
    // bgcolor: 'dkgreen-8',
    // border: 'border-top-2-dkgreen-1',
    bggradient: 'algae',
    expandcol: 5,
    buttonText: 'See direct selling templates',
    buttonPath: '/tag/direct%20selling',
    buttonClass: 'btn-cta-outline',
  },
]

const industryExamples = [
  {
    title: 'Seasonal product campaigns',
    titlecolor: 'yellow-1',
    desccolor: 'black-1',
    image: 'exampleDirectMarketing1',
    imageShadow: true,
    imageBorderColor: 'white-1',

    lineClass: 'yellow-5',
    iconShow: true,
    iconContext: 'storefront',
    iconColor: 'yellow',
    description:
      'Showcase the latest product offerings in your promotional calendar with easy-to-customize templates',
  },
  {
    title: 'Offers and promotions',
    titlecolor: 'pink-1',
    desccolor: 'black-1',
    image: 'exampleDirectMarketing2',
    imageShadow: true,
    imageBorderColor: 'white-1',

    lineClass: 'pink-4',
    iconShow: true,
    iconContext: 'tag',
    iconColor: 'pink',
    description:
      'Drive direct revenue by equipping your team to share image-rich  offers, promotions, and communications',
  },
  {
    title: 'Lifestyle content & inspiration',
    description:
      'Engage customers with inspiration, lifestyle content and tips & tricks with prebuilt visual assets',
    image: 'exampleDirectMarketing3',
    imageShadow: true,
    imageBorderColor: 'white-1',

    iconShow: true,
    iconContext: 'lightbulb',
    iconColor: 'bluegreen',
    lineClass: 'bluegreen-4',
  },
  {
    title: 'Brand events',
    description:
      'Help prospects discover the benefits of your events and reward activities with event templates',
    image: 'exampleDirectMarketing4',
    imageShadow: true,
    imageBorderColor: 'white-1',

    iconShow: true,
    iconContext: 'home',
    iconColor: 'sky',
    lineClass: 'sky-4',
  },
  {
    title: 'Recruiting',
    description:
      'Encourage others to join your organization and highlight its benefits with visual compelling graphics',
    image: 'exampleDirectMarketing5',
    imageShadow: true,
    imageBorderColor: 'white-1',

    iconShow: true,
    iconContext: 'handshake',
    iconColor: 'red',
    // lineClass: 'red-4',
  },
]
const featureData = {
  title:
    'Drive action from your affiliates with easy-to-customize visual content',
  titlecolor: 'black-4',
  mode: 'triovertical',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Seasonal product campaigns',
          titlecolor: 'bluegreen-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'bluegreen-1',
          iconname: 'storefront',
          iconcolor: 'bluegreen',
          path: '/platform/content-marketing/',
          description:
            'Showcase the latest product offerings in your promotional calendar with easy-to-customize templates',
        },
        {
          title: 'Offers and promotions',
          titlecolor: 'purple-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'purple-1',
          iconname: 'tag',
          iconcolor: 'purple',
          path: '/platform/offers-promotions/',
          description:
            'Drive direct revenue by equipping your team to share image-rich  offers, promotions, and communications',
        },
        {
          title: 'Lifestyle content & inspiration',
          titlecolor: 'blue-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'dkblue-1',
          iconname: 'lightbulb',
          iconcolor: 'blue',
          path: '/platform/offers-promotions/',
          description:
            'Engage customers with inspiration, lifestyle content and tips & tricks with prebuilt visual assets',
        },
        {
          title: 'Brand events',
          titlecolor: 'orange-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'orange-1',
          iconname: 'beach',
          iconcolor: 'orange',
          path: '/platform/pulse-surveys/',
          description:
            'Help prospects discover the benefits of your events and reward activities with event templates',
        },
      ],
    },
  ],
}

const templateCallout = {
  noun: 'business',
  adjective: 'direct selling',
  path: '/tag/direct%20selling',
  description:
    'See direct selling & network marketing templates from our community',
}

const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Direct Marketing - Visual Content Marketing"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how Storylava can help you drive more revenue and customer/distributor engagement with your direct marketing business',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium green-1">
            Drive ROI from your direct marketing network
          </h1>
          <p class="display-4">
            Enable your network to promote products, drive revenue, and showcase
            your opportunity with easily customizable visual social templates
          </p>
        </div>
      </div>
    </div>

    <AlternatingCallout featureData={useCaseData} />

    {/* SELECTED FEATURES */}
    {/* <FeatureQuad featureData={featureData} /> */}
    <AlternatingCallout shift={true} featureData={industryExamples} />
    <TemplateCalloutLink data={templateCallout} />

    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About
